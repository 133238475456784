
import { defineComponent } from 'vue'

export default defineComponent({
    name: "SideBar",
    data(){
        return{
            appVersion: process.env.VUE_APP_VERSION
        }
    },
    props:{
        adminOptions:{
            type: Array as any[any]
        },
        options:{
            type: Array as any[any]
        }
    }
})
