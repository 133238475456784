import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "navbar",
  class: "top-0 left-0 w-full h-16 md:h-20 px-4 md:px-8 flex items-center justify-between z-10 sticky bg-darkgray text-white"
}
const _hoisted_2 = { class: "start flex items-center gap-4 text-white flex-none" }
const _hoisted_3 = { class: "text-md font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "icon w-5 h-5 bg-white",
        style: _normalizeStyle('-webkit-mask-image: url(' + $props.icon + '); -webkit-mask-repeat: no-repeat; -webkit-mask-position: center; -webkit-mask-size: cover;')
      }, null, 4),
      _createElementVNode("p", _hoisted_3, _toDisplayString($props.name), 1)
    ]),
    _renderSlot(_ctx.$slots, "default")
  ], 512))
}