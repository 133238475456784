import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Banners from '../views/Banners.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: '/banners'
  },
  {
    path: '/banners',
    name: 'Banners',
    component: Banners
  },
  {
    path: '/cinemas',
    name: 'Cinemas',
    component: function() {
      return import ('../views/Cinemas.vue')
    }
  },
  {
    path: '/cinemas/:id',
    name: 'Cinemas Single',
    component: function() {
      return import ('../views/CinemasSingle.vue')
    }
  },
  {
    path: '/peliculas',
    name: 'Movies',
    component: function() {
      return import ('../views/Movies.vue')
    }
  },
  {
    path: '/ventas-web',
    name: 'Ventas',
    component: function() {
      return import ('../views/Sales.vue')
    }
  },
  {
    path: '/mercado-pago',
    name: 'Mercado Pago',
    component: function() {
      return import ('../views/PaymentCredentials.vue')
    }
  },
  {
    path: '/mercado-pago/:status',
    name: 'Mercado Pago Status',
    component: function() {
      return import ('../views/PaymentCredentials.vue')
    }
  },
  {
    path: '/reembolso/:saleId',
    name: 'Reembolso',
    component: function() {
      return import ('../views/RefundSale.vue')
    }
  },
  {
    path: '/reembolso/:saleId/:status',
    name: 'Reembolso Respuesta',
    component: function() {
      return import ('../views/RefundSale.vue')
    }
  },
  {
    path: '/config',
    name: 'Config',
    component: function() {
      return import ('../views/Config.vue')
    }
  },
  {
    path: '/users',
    name: 'Usuarios',
    component: function() {
      return import ('../views/Users.vue')
    }
  },
  {
    path: '/usuarios/:id',
    name: 'Usuarios Single',
    component: function() {
      return import ('../views/UsersSingle.vue')
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: function() {
      return import ('../views/Login.vue')
    }
  },
  {
    path: '/update',
    name: 'update',
    component: function() {
      return import ('../views/Update.vue')
    }
  },
  {
    path: "/:pathMatch(.*)*",
    name: '404',
    component: function() {
        return import ('../views/404.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
