import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "navBarContainer w-full p-4 fixed left-0 bottom-0 bg-gradient-to-t from-darkgray to-transparent md:hidden" }
const _hoisted_2 = { class: "navBar bg-neutral rounded-full px-4 flex items-center justify-between border border-stone" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          class: "w-full",
          to: option.link,
          key: index
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["menu-option flex flex-col text-white gap-2 items-center p-4", (option.link == _ctx.$route.path) ? 'opacity-100 border-b border-white' : 'opacity-50'])
            }, [
              _createElementVNode("div", {
                class: "icon w-5 h-5 bg-white",
                style: _normalizeStyle('-webkit-mask-image: url('+option.icon+'); -webkit-mask-repeat: no-repeat; -webkit-mask-position: center; -webkit-mask-size: cover;')
              }, null, 4)
            ], 2)
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128))
    ])
  ]))
}