import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "toast-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["toast-container fixed left-0 right-0 bg-yellow-200 p-8 m-8 rounded-xl transition-all duration-500 shadow-2xl flex items-center justify-between z-20 text-center", _ctx.showToast ? 'bottom-0' : '-bottom-80'])
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.message), 1),
    _createElementVNode("div", {
      class: "icon w-6 h-6 bg-black",
      style: _normalizeStyle('-webkit-mask-image: url(/assets/icons/info.svg); -webkit-mask-size: cover;')
    }, null, 4)
  ], 2))
}