
// Import Custom Components
import SectionHeader from "@/components/SectionHeader.vue"
import Banner from "@/components/Banner.vue"
import Toast from "@/components/Toast.vue"

// axios
import axios from 'axios'

// upload
import { upload } from 'upload';

import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Banners',
    components: {
        SectionHeader,
        Banner,
        Toast
    },
    data() {
        return {
            sectionIcon: "/assets/icons/albums-outline.svg",
            sectionName: "Banners",
            banners: [] as any[any],
            loading: true
        }
    },
    async created() {
        //get company id
        let companyId = this.$store.state.user.companyId

        //get banners
        this.getBanners(companyId)
    },
    methods: {
        async getBanners(companyId: number) {
            //set api url
            const apiUrl = (this as any).apiUrl

            //get banners
            const getBanners = await axios.get(apiUrl + '/banners/' + companyId).catch(error => {
                console.log(error.response)
                throw 'Error de Servidor'
            })

            this.banners = getBanners.data.data

            this.loading = false
        },
        upload: async function (event: Event, movieId: string, contentType: string) {

            this.loading = true

            // get file input
            const input = event.target

            //get file
            const file = (input as any).files[0]

            //get api url
            const apiUrl = (this as any).apiUrl

            //get cinema id
            const cinemaId = (this as any).$store.state.cinemas[0].cinemaId

            //get company id
            const companyId = this.$store.state.user.companyId

            const response = await upload(
                apiUrl + '/file',
                {
                    file: file as File,
                    cinemaId: cinemaId,
                    companyId: companyId,
                    movieId: movieId,
                    contentType: contentType
                }
            ) as any

            const jsonResponse = JSON.parse(response.data)

            if (jsonResponse.status == 'error') {
                const toast = (this.$refs as any).toast
                toast.show(jsonResponse.message)
            }

            //get banners
            this.getBanners(this.$store.state.user.companyId)
        },
        async removeFile(fileId: number) {
            //get api url
            const apiUrl = (this as any).apiUrl

            //delete banner
            await axios.delete(apiUrl + '/file/' + fileId).catch(error => {
                console.log(error.response)
                throw 'Error de Servidor'
            })

            //get banners
            this.getBanners(this.$store.state.user.companyId)
        }
    }
})
