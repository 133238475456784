import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

//storage
import storage from './storage'

//tailwind
import './css/tailwind.css'

const app = createApp(App)

app.config.globalProperties.$store = storage;

app.config.globalProperties.apiUrl =    (process.env.NODE_ENV === 'production')
                                        ? window.location.protocol+'//api.gaf.adro.studio'
                                        : window.location.protocol+'//api.gaf.local'

app.use(router).use(storage).mount('#app')
