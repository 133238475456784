
// Import Custom Components
import SideBar from '@/components/SideBar.vue'
import NavBar from '@/components/NavBar.vue'

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Inicio',
  components: {
    SideBar,
    NavBar
  },
  data() {
    return {
      cinemaInfo: [] as any[any],
      adminOptions: [],
      /*adminOptions: [
        {
          link: '/cinemas',
          label: 'Cines',
          icon: '/assets/icons/grid.svg'
        },
        {
          link: '/users',
          label: 'Usuarios',
          icon: '/assets/icons/person-outline.svg'
        },
        {
          link: '/update',
          label: 'Update',
          icon: '/assets/icons/cloud-upload.svg'
        }
      ],*/
      menuOptions: [
        {
          link: '/banners',
          label: 'Banners',
          icon: '/assets/icons/albums-outline.svg'
        },
        {
          link: '/peliculas',
          label: 'Peliculas',
          icon: '/assets/icons/film-outline.svg'
        },
        {
          link: '/ventas-web',
          label: 'Ventas Web',
          icon: '/assets/icons/ticket.svg'
        },
        {
          link: '/mercado-pago',
          label: 'Mercado Pago',
          icon: '/assets/icons/card-outline.svg'
        },
        /*{
          link: '/config',
          label: 'Config',
          icon: '/assets/icons/settings.svg'
        },*/
        {
          link: '/login?action=logout',
          label: 'Salir',
          icon: '/assets/icons/arrow-right-from-bracket-solid.svg'
        }
      ]
    }
  },
  async created() {
    //check if logged in
    if (!(this as any).$store.state.isAuth) {
      this.$router.push('/login')
    }

    console.log(this.$store.state.user.role)

  }
})
