//create store
import { createStore } from 'vuex'

//vuex persist
import vuexPersistance from 'vuex-persist';

const storage = createStore({
    state: {
        isAuth: false,
        user: [],
        cinemas: [],
        selectedCinema: 0
    },
    mutations: {
        setAuth(state, status) {
            state.isAuth = status
        },
        setUserData(state, data) {
            state.user = data
        },
        setUserCinemas(state, data) {
            state.cinemas = data
        },
        setSelectedCinema(state, id){
            state.selectedCinema = id
        }
    },
    plugins:[
        new vuexPersistance({
            storage: window.localStorage
        }).plugin
    ]
})

export default storage