

import { defineComponent } from 'vue'

export default defineComponent({
    name: "Toast",
    data(){
        return{
           showToast: false,
           message: '',
        }
    },
    methods:{
        show: function(message: string){
            this.message = message
            this.showToast= true

            setTimeout( () => {
                this.showToast= false
            }, 3000)
        }
    }      
})


