import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { ref: "root" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideBar = _resolveComponent("SideBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_NavBar = _resolveComponent("NavBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", {
      class: _normalizeClass((_ctx.$route.path == '/login') ? 'ml-0' : 'md:ml-64 pb-16 mb-2 md:pb-0 md:mb-0')
    }, [
      ((_ctx.$route.path != '/login'))
        ? (_openBlock(), _createBlock(_component_SideBar, {
            key: 0,
            options: _ctx.menuOptions,
            adminOptions: _ctx.adminOptions
          }, null, 8, ["options", "adminOptions"]))
        : _createCommentVNode("", true),
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          _createVNode(_Transition, { name: "fade" }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      }),
      ((_ctx.$route.path != '/login'))
        ? (_openBlock(), _createBlock(_component_NavBar, {
            key: 1,
            options: _ctx.menuOptions
          }, null, 8, ["options"]))
        : _createCommentVNode("", true)
    ], 2)
  ], 512))
}